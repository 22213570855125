<template>
    <div style="display: flex; width: 100%; height: 100%; flex-direction: column; overflow: hidden;">
        <vue-html2pdf
            :show-layout="false"
            :filename="'Buyer Profile: ' + Customer.otsname"
            :float-layout="true"
            :enable-download="false"
            :html-to-pdf-options="htmlToPdfOptions"
            :paginate-elements-by-height="1120"
            :manual-pagination="true"
            :pdf-quality="2"
            :preview-modal="false"
            pdf-format="a4"
            pdf-content-width="750px"
            ref="html2Pdf"
            @beforeDownload="beforeDownload($event)"
        >
            <section slot="pdf-content">
                <v-app>
                    <div class="d-flex flex-column fill-height paper-a4">
                        <v-col class="pa-0 ma-0 mb-auto">
                            <v-row no-gutters class="pt-1">
                                <v-col cols="12" class="font-weight-bold">{{ $t('message.buyerProfile') + ': ' + Customer.otsname }}</v-col>
                            </v-row>
                            <v-row no-gutters class="pt-1">
                                <v-col cols="4" class="">{{ $t('message.name') }}</v-col>
                                <v-col cols="8">{{ Customer.title }}</v-col>
                            </v-row>
                            <v-row no-gutters class="pt-1">
                                <v-col cols="4" class="">{{ $t('message.address') }}</v-col>
                                <v-col cols="8" class="d-flex flex-column">
                                    <span>{{ Company.address1 }}</span>
                                    <span>{{ Company.address2 }}</span>
                                    <span>{{ Company.address3 }}</span>
                                    <span>{{ Company.city + ' ' + (Company.postcode != null ? Company.postcode : '') }}</span>
                                    <span>{{ Company.state }}</span>
                                    <span>{{ Country.name }}</span>
                                </v-col>
                            </v-row>
                            <v-row no-gutters class="pt-1">
                                <v-col cols="4" class="">{{ $t('message.taxNumber') }}</v-col>
                                <v-col cols="8">{{ getTaxId() }}</v-col>
                            </v-row>
                            <v-row no-gutters class="pt-1">
                                <v-col cols="4" class="">{{ $t('message.officeTelephone') }}</v-col>
                                <v-col cols="8">{{ Company.tel != null ? Company.tel : $t('message.na') }}</v-col>
                            </v-row>
                            <v-row no-gutters class="pt-1">
                                <v-col cols="4" class="">{{ $t('message.salesColleague') }}</v-col>
                                <v-col cols="8">{{ Salescontact.name != null ? Salescontact.name : $t('message.na') }}</v-col>
                            </v-row>
                            <v-row no-gutters class="pt-1">
                                <v-col cols="4" class="">{{ $t('message.salesTerritory') }}</v-col>
                                <v-col cols="8">{{ Territory.title != null ? Territory.title : $t('message.na') }}</v-col>
                            </v-row>
                            <v-row no-gutters class="pt-1">
                                <v-col cols="4" class="">{{ $t('message.website') }}</v-col>
                                <v-col cols="8">{{ Company.website != null && Company.website != '' ? Company.website : $t('message.na') }}</v-col>
                            </v-row>
                            <v-row no-gutters class="pt-1">
                                <v-col cols="4" class="">{{ $t('message.marketSegment') }}</v-col>
                                <v-col cols="8">{{ Marketsegment.title != null ? Marketsegment.title : $t('message.na')  }}</v-col>
                            </v-row>
                            <v-row no-gutters class="pt-1">
                                <v-col cols="4" class="">{{ $t('message.contacts') }}</v-col>
                                <v-col cols="8" class="d-flex flex-column">
                                    <template v-for="contact in Contacts" v-if="Contacts.length > 0">
                                        <div :key="contact.Contact.id">
                                            <v-row no-gutters class="pt-2">
                                                <v-col cols="12">{{ contact.Contact.prefix + " " + contact.Contact.name }}</v-col>
                                            </v-row>
                                            <v-row no-gutters>
                                                <v-col cols="4" class="">{{ $t('message.department') }}</v-col>
                                                <v-col cols="8">{{ contact.CompanyDepartment.name }}</v-col>
                                            </v-row>
                                            <v-row no-gutters>
                                                <v-col cols="4" class="">{{ $t('message.position') }}</v-col>
                                                <v-col cols="8">{{ contact.Contact.position }}</v-col>
                                            </v-row>
                                            <v-row no-gutters>
                                                <v-col cols="4" class="">{{ $t('message.hp') }}</v-col>
                                                <v-col cols="8">{{ contact.Contact.hp }}</v-col>
                                            </v-row>
                                            <v-row no-gutters>
                                                <v-col cols="4" class="">{{ $t('message.email') }}</v-col>
                                                <v-col cols="8">{{ contact.Contact.email }}</v-col>
                                            </v-row>
                                            <v-row no-gutters>
                                                <v-col cols="4" class="">{{ $t('message.autoEmail') }}</v-col>
                                                <v-col cols="8">{{ contact.Contact.otsnotification == 1 ? $t('message.yes') : $t('message.no') }}</v-col>
                                            </v-row>
                                            <v-row no-gutters>
                                                <v-col cols="4" class="">{{ $t('message.appicAccess') }}</v-col>
                                                <v-col cols="8">
                                                    {{ contact.Contact.existing_appic_access.length > 0 ? $t('message.yes') : (contact.Contact.appic_status == 1 ? $t('message.yes') : $t('message.no')) }}
                                                </v-col>
                                            </v-row>
                                        </div>
                                    </template>
                                    <template v-else>{{ $t('message.na') }}</template>
                                </v-col>
                            </v-row>
                            <v-row no-gutters class="pt-1">
                                <v-col cols="4" class="">{{ $t('message.portOfDischarge') }}</v-col>
                                <v-col cols="8">{{ Shippingport.title != null ? Shippingport.title : $t('message.na') }}</v-col>
                            </v-row>
                            <v-row no-gutters class="pt-1">
                                <v-col cols="4" class="">{{ $t('message.buyerSpecificRequests') }}</v-col>
                                <v-col cols="8">
                                    <div v-html="nl2br(purify(Customer.doc_setting))" v-if="Customer.doc_setting != null && Customer.doc_setting != ''"></div>
                                    <div v-else>{{ $t('message.na') }}</div>
                                </v-col>
                            </v-row>
<!--                            <v-row no-gutters class="pt-1">-->
<!--                                <v-col cols="4" class="">{{ $t('message.taxId') }}</v-col>-->
<!--                                <v-col cols="8">{{ Company.gstno != null ? Company.gstno : $t('message.na') }}</v-col>-->
<!--                            </v-row>-->
<!--                            <v-row no-gutters class="pt-1" v-if="Company.country_id == 5">-->
<!--                                <v-col cols="4" class="">{{ $t('message.uscc') }}</v-col>-->
<!--                                <v-col cols="8">{{ Company.uscc != null ? Company.uscc : $t('message.na') }}</v-col>-->
<!--                            </v-row>-->
                            <v-row no-gutters class="pt-1">
                                <v-col cols="4" class="">{{ $t('message.directServiceSales') }}</v-col>
                                <v-col cols="8">{{ Dspaymentstatement.title != null ? Dspaymentstatement.title : $t('message.na') }}</v-col>
                            </v-row>
                            <v-row no-gutters class="pt-1">
                                <v-col cols="4" class="">{{ $t('message.warehouseSales') }}</v-col>
                                <v-col cols="8">{{ Lspaymentstatement.title != null ? Lspaymentstatement.title : $t('message.na') }}</v-col>
                            </v-row>
                            <v-row no-gutters class="pt-1">
                                <v-col cols="4" class="">{{ $t('message.currentApprovedCredit') }}</v-col>
                                <v-col cols="8">
                                    <span v-if="CreditLimit.amount > 0">{{ formatThisNumber(CreditLimit.amount,'0,0') + ' ' + getCurrencyCode(CreditLimit.currency_id)}}</span>
                                    <span v-else>{{ $t('message.na') }}</span>
                                </v-col>
                            </v-row>
                            <v-row no-gutters class="pt-1">
                                <v-col cols="4" class="">{{ $t('message.alternateAddress') }}</v-col>
                                <v-col cols="8" class="d-flex flex-column">
                                    <template v-if="Addresses.length > 0">
                                        <template v-for="address in Addresses">
                                            <div :key="address.Address.id">
                                                <v-row no-gutters class="pt-2">
                                                    <v-col cols="12">{{ address.Address.title }}</v-col>
                                                </v-row>
                                                <v-row no-gutters>
                                                    <v-col cols="4" class="">{{ $t('message.address') }}</v-col>
                                                    <v-col cols="8">
                                                        <div v-html="nl2br(purify(address.Address.address))"></div>
                                                    </v-col>
                                                </v-row>
                                            </div>
                                        </template>
                                    </template>
                                    <template v-else>{{ $t('message.na') }}</template>
                                </v-col>
                            </v-row>
                        </v-col>
                    </div>
                </v-app>
            </section>
        </vue-html2pdf>
    </div>
</template>

<script>
import { api } from "Api";
import VueHtml2pdf from "vue-html2pdf";
import {nl2br, numberFormat, purify} from "Helpers/helpers";
import {mapGetters, mapActions} from "vuex";
import S3 from 'aws-s3-pro';

export default {
    name: "BuyerProfileView",
    props: ["buyerId"],
    components: {VueHtml2pdf},
    data() {
        return {
            htmlToPdfOptions: {
                image: {
                    type: 'jpeg',
                    quality: 0.95
                },
                html2canvas: {
                    scale: 1.5
                }
            },
            Addresses: [],
            Company: {},
            Contacts: [],
            Country: {},
            Customer: {},
            CreditLimit: {
                amount: 0,
                currency_id: 1
            },
            Dspaymentstatement: {},
            Lspaymentstatement: {},
            Marketsegment: {},
            Salescontact: {},
            Shippingport: {},
            Territory: {}
        }
    },
    computed: {
        ...mapGetters('currency', {
            allCurrencies: 'allCurrencies'
        }),
        ...mapGetters('user',{
            s3Keys : 's3Keys'
        }),
        baseURL(){
            return 'https://apptimber-appic.s3-ap-southeast-1.amazonaws.com'
        },
        config() {
            return {
                bucketName: 'apptimber-appic',
                dirName: 'buyer-profiles',
                region: 'ap-southeast-1',
                accessKeyId: this.s3Keys.id,
                secretAccessKey: this.s3Keys.secret,
                s3Url: this.baseURL
            }
        },
        S3Client(){
            return new S3(this.config);
        }
    },
    methods: {
        ...mapActions('currency',{
            getAllCurrencies: 'getAllCurrencies'
        }),
        async beforeDownload({html2pdf, options, pdfContent}) {
            await html2pdf().set(options).from(pdfContent).toPdf().get('pdf').then((pdf) => {
                let fileName = this.Customer.otsname
                let file = new File([pdf.output('blob')],fileName + '.pdf')
                this.S3Client
                    .uploadFile(file, fileName)
                    .then((response) => {
                        if (response.status == 204) {
                            this.$emit('pdf-printed')
                            window.open(response.location)
                        } else {
                            this.$emit('pdf-not-printed')
                        }
                    })
                    .catch(() => {
                        this.$emit('pdf-not-printed')
                    })
            })
        },
        formatThisNumber (value,format) {
            return numberFormat(value,format)
        },
        getCurrencyCode() {
            let currencyCode = ''
            const currency = this.allCurrencies.find(c => c.Currency.id == this.CreditLimit.currency_id)
            if(currency){
                currencyCode = currency.Currency.code
            }
            return currencyCode
        },
        getTaxId() {
            let taxId = ''
            if(this.Company.gstno != null && this.Company.taxno_type != null) taxId = this.Company.taxno_type + ': ' + this.Company.gstno
            if(this.Company.gstno != null && this.Company.taxno_type == null) taxId = this.Company.gstno
            return taxId
        },
        loadBuyerById (buyerId) {
            let self = this
            return new Promise((resolve, reject) => {
                api
                    .get("/customers/" + buyerId + "/view",{
                    })
                    .then(response => {
                        for(let key in response.data.data[0]){
                            self[key] = response.data.data[0][key]
                        }
                        resolve(buyerId)
                    })
                    .catch(error => {
                        reject(error);
                    })
            })
        },
        loadBuyerContacts (companyId) {
            return new Promise((resolve, reject) => {
                api
                    .get("/companies/" + companyId + "/contacts")
                    .then(response => {
                        this.Contacts = response.data.data
                        resolve(companyId);
                    })
                    .catch(error => {
                        reject(error);
                    })
            })
        },
        loadBuyerAddresses (buyerId) {
            return new Promise((resolve, reject) => {
                api
                    .get("/customers/" + buyerId + "/addresses")
                    .then(response => {
                        this.Addresses = response.data.data
                        resolve(buyerId);
                    })
                    .catch(error => {
                        reject(error);
                    })
            })
        },
        nl2br,
        purify
    },
    created(){
        if(this.allCurrencies.length == 0) this.getAllCurrencies()
        this.loadBuyerById(this.buyerId)
            .then(() => {
                this.loadBuyerContacts(this.Company.id)
                    .then(() => {
                        this.loadBuyerAddresses(this.buyerId)
                            .then(() => {
                                this.$refs.html2Pdf.generatePdf()
                            })
                            .catch(() => {
                                this.$refs.html2Pdf.generatePdf()
                            })
                    })
                    .catch(() => {
                        this.$refs.html2Pdf.generatePdf()
                    })
            })
            .catch(() => {
                this.$emit('pdf-not-printed')
            })
    }
}
</script>

<style scoped>
@font-face {
    font-family: 'AppicArial';
    src: url('/static/fonts/Arial.woff2') format('woff2'),
    url('/static/fonts/Arial.woff2') format('woff'),
    url('/static/fonts/Arial.woff2') format('truetype');
}
.col-2-5 {
    flex: 0 0 20.83333333%;
    max-width: 20.83333333%;
}
.col-3-5 {
    flex: 0 0 29.16666667%;
    max-width: 29.16666667%;
}
.col-4-5 {
    flex: 0 0 37.5%;
    max-width: 37.5%;
}
.col-9-5 {
    flex: 0 0 79.16666667%;
    max-width: 79.16666667%;
}
.border-top-1 {
    border-top: 1px solid black !important;
}
.border-bottom-1 {
    border-bottom: 1px solid black !important;
}
.border-top-2 {
    border-top: 2px solid black;
}
.border-bottom-2 {
    border-bottom: 2px solid black !important;
}
.paper-a4 {
    height: 1065px;
    margin-left: 75px;
    margin-top: 35px;
    background-color: #FFFFFF !important;
    color: #000000 !important;
}
.v-application{
    font-family:'AppicArial' !important;
    line-height:16px !important;
    font-size:9pt !important;
    padding:4px !important;
}
.v-application{
    font-family: 'AppicArial' !important;
    line-height:16px !important;
    font-size:9pt !important;
    padding:4px !important;
    background-color: #FFFFFF !important;
}
body {
    width: 18.5cm !important;
    margin: 0 !important;
}
* {
    text-rendering: geometricprecision !important;
}
</style>